export const DICTIONARIES_INIT = 'Dictionaries:init';
export const DICTIONARIES_FETCH_BODY_TYPES = 'Dictionaries:fetch.bodyTypes';
export const DICTIONARIES_FETCH_CURRENCIES = 'Dictionaries:fetch.currencies';
export const DICTIONARIES_FETCH_CARGO_TYPES = 'Dictionaries:fetch.cargoTypes';
export const DICTIONARIES_FETCH_COUNTRIES = 'Dictionaries:fetch.countries';
export const DICTIONARIES_FETCH_WAYS_OF_LOADING = 'Dictionaries:fetch.waysOfLoading';
export const DICTIONARIES_FETCH_VEHICLE_SIZES = 'Dictionaries:fetch.vehicleSizes';
export const DICTIONARIES_FETCH_PAYMENT_CONDITIONS = 'Dictionaries:fetch.paymentConditions';
export const DICTIONARIES_FETCH_ADRS = 'Dictionaries:fetch.adrs';
export const DICTIONARIES_FETCH_CUSTOM_DICTIONARIES = 'Dictionaries:fetch.customDictionaries';
export const DICTIONARIES_FETCH_OFFERS_SOURCES = 'Dictionaries:fetch.offerSources';
